import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import FlexibleApp from 'pages/settings/FlexibleApp'
import Interrupt from 'pages/settings/Interrupt'
import TwoFactorDemo from 'pages/settings/TwoFactorDemo'

const Settings = () => {
  return (
    <VStack w='full' pb={4} spacing={4}>
      <Tabs w='full' isLazy>
        <TabList>
          <Tab>Flexible app</Tab>
          <Tab>TwoFactor Demo</Tab>
          <Tab>Interrupt prompt</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <FlexibleApp />
          </TabPanel>
          <TabPanel>
            <TwoFactorDemo />
          </TabPanel>
          <TabPanel>
            <Interrupt />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}

export default Settings
